import { DateTime, Duration } from "luxon";
import {
    faceMassage,
    hairCut, hairDye, hairWash, headMassage,
    shaving,
} from "@/cng-icons";

const SERVICES = {
    "cut": {
        name: "Cut",
        icon: hairCut,
    },
    "wash": {
        name: "Wash",
        icon: hairWash,
    },
    "shave": {
        name: "Shave",
        icon: shaving,
    },
    "color": {
        name: "Color",
        icon: hairDye,
    },
    "face": {
        name: "Face Massage",
        icon: faceMassage,
    },
    "head": {
        name: "Head Massage",
        icon: headMassage,
    },
};

const SERVICE_TYPES = Object.keys(SERVICES);

export default {
    BACKEND_URL: {
        production: "https://cut-n-go.azurewebsites.net/api", // production
        preview: "https://cut-n-go-preview.azurewebsites.net/api", // main
        local: "http://localhost:7071/api", // local
    },
    LOCAL_LOCATION: "http://localhost:8100/",
    DATETIME_UNSET: DateTime.invalid("unset"),
    DURATION_ZERO: Duration.fromMillis(0),
    DATE_FORMAT: DateTime.DATE_SHORT,
    TIME_FORMAT: DateTime.TIME_SIMPLE,
    WEEKDAY_FORMAT: { weekday: "short" },
    SERVICE_TYPES,
    SERVICES,
    DEFAULT_VAT_PCT: 0,
    DEFAULT_CURRENCY: "AED",
    USERNAME_PLACEHOLDER: "< no name >",
    AVATAR_PLACEHOLDER_URL: "/assets/avatar_placeholder.png",
}
