// store a ref to capacitor plugins, just in case...
// import { Plugins } from '@capacitor/core';
// window.Plugins = Plugins;

import { createApp } from "vue"
import App from "@/App.vue"
import store from "@/store";
import { create_router } from "@/router";
import constants from "@/constants";

import { IonicVue } from "@ionic/vue";

/* Core CSS required for Ionic components to work properly */
import "@ionic/vue/css/core.css";

/* Basic CSS for apps built with Ionic */
import "@ionic/vue/css/normalize.css";
import "@ionic/vue/css/structure.css";
import "@ionic/vue/css/typography.css";

/* Optional CSS utils that can be commented out */
import "@ionic/vue/css/padding.css";
import "@ionic/vue/css/float-elements.css";
import "@ionic/vue/css/text-alignment.css";
import "@ionic/vue/css/text-transformation.css";
import "@ionic/vue/css/flex-utils.css";
import "@ionic/vue/css/display.css";

/* Theme variables */
import "./theme/variables.css";
import "./theme/styles.less";

// for adding custom icons
import { addIcons } from "ionicons";

// for web, the Firebase app must be initialised prior to importing FirebaseAuthentication
/*
 `firebase.config.json` isn't checked in, but it must contain the `firebaseConfig` structure you
 find on the Firebase console at your Web App, only in .json format, e.g.:
  {
    "apiKey": "...",
    "authDomain": "whatever.firebaseapp.com",
    "projectId": "whatever",
    "storageBucket": "whatever.appspot.com",
    "messagingSenderId": "...",
    "appId": "1:...:web:..."
  }
*/
import  { initializeApp } from "firebase/app";

import { ax, connect_pubsub } from "@/backend";
import { show_error } from "@/dialogs";

async function set_sid(sid) {
  console.log(`setting sid header to axios: ${sid}`);
  const methods = [ "get", "post", "put", "patch", "delete" ]; // NOT for "option"

  const store_group_members = async () => {
    const response = await ax.get("/v1/person");
    await store.dispatch("persist/setGroupMembers", response.data);
  };

  if (sid) {
    for (const method of methods) {
      ax.defaults.headers[method]["x-cutngo-sid"] = sid;
    }
    // check if the sid is indeed valid
    try {
      const response = await ax.get("/v1/whoami");
      if (!response.data.roles.includes("employee")) {
        console.log("this user is not an employee");
        await show_error("Not an employee");
        throw new Error("User is not an employee");
      }
      store.commit("persist/setSid", sid);

      const tasks = [
        store.dispatch("persist/setUserDetails", response.data),
        connect_pubsub(),
      ];
      if (store.state.persist.user_roles.includes("group")) {
        tasks.push(store_group_members());
      }
      await Promise.all(tasks);
      return true;
    }
    catch (error) {
      console.log(error);
      console.log("sid expired/invalid, will need to sign in again");
    }
  }

  for (const method of methods) {
    delete ax.defaults.headers[method]["x-cutngo-sid"];
  }
  await store.dispatch("persist/reset");
  //await store.dispatch("work/reset");
}


(async () => {
  if (typeof(cordova) === "undefined") {
    console.log("initialising web app");
    const firebaseConfig = require("../firebase.config.json");
    initializeApp(firebaseConfig);
  }

  console.log("waiting for store");
  await store.restored;
  store.commit("tick");

  // initialise axios base URL
  const backend_type = store?.state?.persist?.backend_type || "preview";
  const backend_url = constants.BACKEND_URL[backend_type];
  if (backend_url) {
    ax.defaults.baseURL = backend_url;
  }

  addIcons({
    faceMassage: "/assets/icon/face_massage.svg",
    hairCut: "/assets/icon/hair_cut.svg",
    hairDye: "/assets/icon/hair_dye.svg",
    hairWash: "/assets/icon/hair_wash.svg",
    headMassage: "/assets/icon/head_massage.svg",
  });

  const router = create_router();
  // some components like ion-fab-button.href rely on the router being part of the document:
  //     const router = document.querySelector('ion-router');
  // so we must fake it...
  const router_element = document.createElement("ion-router");
  Object.assign(router_element, router);
  document.children[0].append(router_element);

  console.log("setting up persisted sid");
  await set_sid(store.state.persist.sid);

  console.log("creating app");
  let app = createApp(App)
      .use(IonicVue)
      .use(store)
      .use(router);

  // until Vue 3.3: https://vuejs.org/guide/components/provide-inject.html#working-with-reactivity
  app.config.unwrapInjectedRef = true;

  // https://stackoverflow.com/questions/63100658/add-global-variable-in-vue-js-3
  /* for (const [k, v] of Object.entries(constants)) {
    app.config.globalProperties[k] = v;
  }*/

  // provide global resources
  app.provide("set_sid", set_sid);

  app.mount("#app");

  console.log("waiting for router");
  await router.isReady();
  router.push("/");

  console.log("running");
})();
