import http from "http";
import axios from "axios";
import event_bus from "@/event-bus";
import { delay } from "@/utils";
import { show_error } from "@/dialogs";

const httpAgent = new http.Agent({ keepAlive: true });
export const ax = axios.create({
    httpAgent,
    withCredentials: false, // we'll add and manage the cookie by ourselves
    // baseURL: will be set according to store setting
});

export async function call_backend_raw(method, url, data, headers) {
    console.log(`${method} ${url} <- ${JSON.stringify(data)}`);
    const request_config = {
        method,
        url,
        headers,
    };
    if (method === "GET") {
        request_config.params = data;
    }
    else {
        request_config.data = data;
    }
    const response = await ax.request(request_config);
    console.log(`${method} ${url} -> ${JSON.stringify(response.data)}`);
    return response.data;
}


export async function call_backend(method, url, data, headers) {
    try {
        return call_backend_raw(method,url, data, headers);
    } catch (error) {
        await show_error(error);
    }
}


let ws_retry_wait = 1;
let ws = null;

export async function connect_pubsub() {
    if (ws && (ws.readyState === 1)) {
        // we might need to reconnect because the persisted x-cutngo-sid session expired
        ws.close();
    }
    const pubsub_token_response = await call_backend("GET", "/v1/pubsub");
    console.log(`pubsub token response: ${JSON.stringify(pubsub_token_response, null, 2)}`);

    const url = pubsub_token_response.token.url;

    window.$ws = ws = new WebSocket(url, "json.webpubsub.azure.v1");
    ws.addEventListener("open", () => {
        console.log("pubsub connected");
        ws_retry_wait = 1;
    });
    ws.addEventListener("close", async event => {
        console.log(`pubsub closed: code=${event.code}, reason=${event.reason}, wasClean=${event.wasClean}, retry_wait=${ws_retry_wait}`);
        if (event.code === 1000) {
            ws = null;
        }
        else {
            await delay(1000 * ws_retry_wait);
            ws_retry_wait = Math.min(3600, 2 * ws_retry_wait);
            await connect_pubsub();
        }
    });
    ws.addEventListener("error", () => console.log("pubsub error"));
    ws.addEventListener("message", event => {
        const msg = JSON.parse(event.data);
        console.log(`pubsub message received ${JSON.stringify(msg, null, 2)}`);
        event_bus.emit(msg.type, msg);
    });
}


export async function pubsub_send(recipients, data) {
    if (!recipients) {
        return;
    }
    if (typeof(recipients) === "string") {
        recipients = [ recipients ];
    }
    if (ws && (ws.readyState === 1)) {
        for (const recipient of recipients) {
            console.log(`pubsub ${recipient} <- ${JSON.stringify(data)}`);
            ws.send(JSON.stringify({
                type: "sendToGroup",
                group: recipient,
                //ackId: 123412341234,
                noEcho: true,
                dataType: "json",
                data,
            }));
        }
    }
}


