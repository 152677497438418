// dump an axios error
import { ref } from "vue";
import constants from "@/constants";

export function obj_from_keys(keys, value) {
    if (keys.length === undefined) {
        keys = Object.keys(keys);
    }
    const acc = {};
    keys.forEach(k => { acc[k] = ref(value) });
    return acc;
}

export function delay(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
}

export function round(x, digits) {
    const m = 10 ** digits;
    return Math.round(x * m) / m;
}

export function is_number(str) {
    if (str.constructor === Number) {
        return true;
    }
    if (str.constructor !== String) {
        return false;
    }
    return !isNaN(str) && !isNaN(parseFloat(str));
}


// @beforeinput: store the last valid value so @input handlers can revert to that if needed
const store_input_value = event => {
    event.currentTarget.dataset.lastValidValue = event.currentTarget.value;
};


// @beforeinput validator to accept only real numbers
const check_real_number = event => {
    const value = event.currentTarget.value;
    if ((value === "") || (value === null) || (value === "-")) {
        return;
    }
    if (isFinite(value) && is_number(value)) {
        return;
    }
    event.currentTarget.value = event.currentTarget.dataset.lastValidValue;
};

export const vRealNumbers = {
    created: (el) => {
        el.inputMode = "decimal";
        el.addEventListener("beforeinput", store_input_value);
        el.addEventListener("input", check_real_number);
    },
}


// @input validator to accept only cardinal numbers
const check_cardinal_number = event => {
    const value = event.target.value;
    if ((value === "") || (value === null) || (value === "-")) {
        return;
    }
    if (isFinite(value)) {
        const x = parseFloat(value);
        if (x === Math.floor(x)) {
            return;
        }
    }
    event.currentTarget.value = event.currentTarget.dataset.lastValidValue;
};


export const vIntegerNumbers = {
    created: (el) => {
        el.inputMode = "numeric";
        el.addEventListener("beforeinput", store_input_value);
        el.addEventListener("input", check_cardinal_number);
    },
}


export const format_date = dt => dt?.toLocaleString(constants.DATE_FORMAT);
export const format_time = dt => dt?.toLocaleString(constants.TIME_FORMAT);
export const format_weekday = dt => dt?.toLocaleString(constants.WEEKDAY_FORMAT);

