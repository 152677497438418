import { createRouter, createMemoryHistory } from "@ionic/vue-router";
import store from "@/store";

const tab_default = {};

function get_tab_default(path, def) {
  return tab_default[path] || `${path}/${def}`;
}

const routes = [
  {
    path: "/",
    redirect: () => {
      if (!store.getters["persist/has_backend_type"]) {
        return "/settings";
      }
      if (!store.getters["persist/is_signed_in"]) {
        return "/sign-in";
      }
      if (store.getters["persist/is_manager"]) {
        return "/purchases";
      }
      return "/working";
    },
  },
  {
    path: "/sign-in",
    meta: { signed_in: false },
    component: () => import("@/views/SignIn.vue"),
  },
  {
    path: "/sign-out",
    meta: { signed_in: true },
    component: () => import("@/views/SignOut.vue"),
  },
  {
    path: "/working",
    meta: { signed_in: true, manager: false },
    component: () => import("@/views/Working.vue"),
  },
  {
    path: "/send-invoice",
    meta: { signed_in: true },
    component: () => import("@/views/SendInvoice.vue"),
  },
  {
    path: "/purchases",
    meta: { signed_in: true },
    component: () => import("@/views/Purchases.vue"),
  },
  {
    path: "/new_purchase",
    meta: { signed_in: true },
    component: () => import("@/views/NewPurchase.vue"),
  },
  {
    path: "/history",
    meta: { signed_in: true },
    redirect: () => get_tab_default("/history", "invoices"),
  },
  {
    path: "/history/invoices",
    meta: { signed_in: true },
    component: () => import("@/views/Invoices.vue"),
  },
  {
    path: "/history/daily-stats",
    meta: { signed_in: true },
    component: () => import("@/views/DailyWork.vue"),
  },
  {
    path: "/settings",
    meta: { },
    component: () => import("@/views/Settings.vue"),
  },
];

async function check_accessibility(to) {
  const is_signed_in = store.getters["persist/is_signed_in"];
  const is_manager = store.getters["persist/is_manager"];
  const fallback_route = {
    path: "/",
    replace: true,
  };
  if (to.meta?.signed_in === true) {
    if (!is_signed_in) {
      console.log("not signed in -> fallback");
      return fallback_route;
    }
  }
  else if (to.meta?.signed_in === false) {
    if (is_signed_in) {
      console.log("signed in -> fallback");
      return fallback_route;
    }
  }
  if (to.meta?.manager === true) {
    if (!is_manager) {
      console.log("not manager -> fallback");
      return fallback_route;
    }
  }
  else if (to.meta?.manager === false) {
    if (is_manager) {
      console.log("manager -> fallback");
      return fallback_route;
    }
  }
  // update the "history" for each path prefix
  let spos = Infinity;
  while (true) {
    spos = to.path.lastIndexOf("/", spos - 1);
    if (spos <= 0) {
      break
    }
    tab_default[to.path.substr(0, spos)] = to.path;
  }
}

export function create_router() {
  console.log("creating router");
  /*
  - we have multiple tabs (eg. History / Invoices and History / Daily)
  - click History, Invoices and Daily appears
  - click Daily, its page appears
  - click History again, and admire the crash you got

  In @ionic/vue-router/dist/index.esm.js there is a `resetTab` which says:
    `router.go(routeInfo.position - currentHistoryPosition);`
  where both positions are undefined, because createMemoryHistory keeps no state, and no state has no position.

  The problem is that @ionic/vue-router shouldn't assume that we have history and back and position, but
  it does, so we need a workaround...
   */
  const history = createMemoryHistory();
  history.state.position = 0;

  const router = createRouter({
    history,
    routes,
  })

  router.beforeEach(check_accessibility);
  return router;
}
