<template>
  <ion-modal id="time-picker-modal" ref="time_picker_modal_ref">
    <ion-datetime mode="ios" :presentation="presentation" hour-cycle="h23" color="secondary"
                  ref="picker" @ionCancel="time_picker_cancel" @ionChange="time_picker_done">
      <ion-buttons slot="buttons">
        <ion-button @click="cancel()">
          <ion-icon slot="icon-only" :icon="arrowUndo" color="secondary" size="small"/>
        </ion-button>
        <ion-button @click="confirm()">
          <ion-icon slot="icon-only" :icon="checkmark" color="secondary" size="small"/>
        </ion-button>
      </ion-buttons>
    </ion-datetime>
  </ion-modal>
</template>

<script setup>
import { ref } from "vue";
import {
  IonButton, IonButtons,
  IonDatetime,
  IonIcon,
  IonModal,
} from "@ionic/vue";
import {
  arrowUndo,
  checkmark,
} from "ionicons/icons";

import { DateTime } from "luxon";

const time_picker_modal_ref = ref(null);
const time_picker_resolve = [];
const presentation = ref("time");

const pick_date_or_time = (current, no_sooner_than=null, no_later_than=null) => {
  /* async function that displays the time picker and returns a promise with the chosen time */
  if (!current) {
    current = DateTime.now();
  }
  /* NOTE: can't take ref to the ion-datetime directly, because it doesn't exist until the modal is shown,
     so we need to look it up runtime */
  const modal = time_picker_modal_ref.value.$el;
  return modal.present().then(() => {
    const datetime = modal.querySelector("ION-DATETIME");
    if (no_sooner_than) {
      datetime.min = no_sooner_than.toISO();
    }
    if (no_later_than) {
      datetime.max = no_later_than.toISO();
    }
    return datetime.reset(current.toISO()).then(
        () => new Promise(resolve => {
          time_picker_resolve.push(resolve);
        })
    );
  });
};

const time_picker_cancel = () => {
  const resolve = time_picker_resolve.pop();
  if (resolve) {
    resolve(DateTime.invalid("cancelled"));
  }
};

const time_picker_done = event => {
  const resolve = time_picker_resolve.pop();
  if (resolve) {
    const value = event.detail.value;
    resolve((typeof value === "string") ? DateTime.fromISO(value) : DateTime.invalid("invalid"));
  }
};

const pick_time = (current, no_sooner_than=null, no_later_than=null) => {
  presentation.value = "time";
  return pick_date_or_time(current, no_sooner_than, no_later_than);
};

const pick_date = (current, no_sooner_than=null, no_later_than=null)  => {
  presentation.value = "date";
  return pick_date_or_time(current, no_sooner_than, no_later_than);
};

defineExpose({
  pick_time,
  pick_date,
});

</script>

<style scoped>
ion-modal#time-picker-modal {
  --width: fit-content;
  --min-width: 250px;
  --height: fit-content;
  --border-radius: 6px;
  --box-shadow: 0 28px 48px rgba(0, 0, 0, 0.4);
}

ion-modal#time-picker-modal h1 {
  margin: 20px 20px 10px 20px;
}

ion-modal#time-picker-modal ion-icon {
  margin-right: 6px;
  width: 48px;
  height: 48px;
  padding: 4px 0;
  color: #aaaaaa;
}

ion-modal#time-picker-modal .wrapper {
  margin-bottom: 10px;
}
</style>
