<template>
  <ion-button :href="props.href" :disabled="props.disabled" :class="button_class">
    <div class="tab-button">
      <ion-icon aria-hidden="true" :icon="props.icon"/>
      <ion-label><slot/></ion-label>
    </div>
  </ion-button>
</template>

<script setup>
import { computed } from "vue";
import { useRouter } from "vue-router";
import {
  IonButton,
  IonIcon,
  IonLabel,
} from "@ionic/vue";

const props = defineProps({
  disabled: {
    type: Boolean,
    default: false,
  },
  href: String,
  icon: String,
});

const router = useRouter();
const button_class = computed(() => (router.currentRoute.value.path == props.href) ? "active" : "" );
</script>

<style scoped>
ion-button {
  flex: 1;
  height: 100%;
  color: grey;
}

ion-button.active {
  color: var(--ion-color-secondary);
}

div.tab-button {
  display: flex;
  flex-direction: column;
  align-items: center;
}

div.tab-button ion-icon {
  font-size: 22px;
}

div.tab-button ion-label {
  text-transform: none;
  font-size: 85%;
}

</style>
