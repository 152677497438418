import { DateTime } from "luxon";
import VuexPersistence from "vuex-persist";
import { Preferences } from "@capacitor/preferences";

/*
Persistent storage can handle only string values, and JSON.stringify loses the class information (eg. DateTime),
so we need a custom serialisation step that first maps these special objects to nice primitive Objects.
*/

const special_handlers = {
    DateTime: {
        dump: x => x.isValid ? { value: x.toISO() } : { reason: x.invalidReason },
        parse: yy => (yy.value !== undefined) ? DateTime.fromISO(yy.value) : DateTime.invalid(yy.reason),
    },
};


function pre_serialise(x) {
    if (x === null) {
        return null;
    }
    if (typeof(x) !== "object") {
        // plain scalar
        return x;
    }
    if (x.length !== undefined) {
        // array
        return x.map(pre_serialise);
    }
    // object (special or generic)
    const classname = x.constructor.name;
    const handlers = special_handlers[classname];
    if (handlers) {
        const repr = handlers.dump(x);
        repr.__type = classname;
        return repr;
    }
    // generic object
    const repr = {};
    for (const [k, v] of Object.entries(x)) {
        repr[k] = pre_serialise(v);
    }
    return repr;
}


function post_deserialise(yy) {
    if (yy === null) {
        return null;
    }
    if (typeof(yy) !== "object") {
        // plain scalar
        return yy;
    }
    if (yy.length !== undefined) {
        // array
        return yy.map(post_deserialise);
    }
    // object (special or generic)
    if (yy.__type) {
        const handlers = special_handlers[yy.__type];
        if (handlers) {
            return handlers.parse(yy);
        }
    }
    // generic object
    const obj = {};
    for (const [k, v] of Object.entries(yy)) {
        obj[k] = post_deserialise(v);
    }
    return obj;
}


/*
NOTE: We can't use VuexPersistence.modules, nor the default .reducer, .saveState, .restoreState.
The default .reducer converts any embedded object to plain Objects, and .saveState uses JSON.stringify before
passing the state to storage.setItem(), which again clobbers all non-trivial Objects.

So we need to define our own .reducer, .saveState and .restoreState, and as we support only the Capacitor Preferences
as storage, we don't need .storage any longer.

VuexPersistence doesn't store options.modules, so the reducer couldn't refer to it, so now we do store it.

Also, when vuex-persist finally calls `store.updateState`, first it calls `deepmerge` on the new module states,
which again frells up the non-trivial objects, so we must set strictMode=true and in the store define a
RESTORE_MUTATION that does the job the way it should be done.
*/
export class VuexCapacitorPersistence extends VuexPersistence {
    constructor(options) {
        if (typeof options === "undefined") {
            options = {};
        }
        super({
            key: options.key,
            asyncStorage: true,
            strictMode: true,
            reducer(state) {
                if (this.modules === null) {
                    return state;
                }
                return Object.fromEntries(Object.entries(state).filter(a => this.modules.includes(a[0])));
            },
            filter(mutation) {
                if (this.modules === null) {
                    return true;
                }
                const slash_pos = mutation.type.indexOf("/");
                if (slash_pos < 0) {
                    return false;
                }
                const module_name = mutation.type.substring(0, slash_pos);
                return this.modules.includes(module_name);
            },
            async saveState(key, state) {
                const value = JSON.stringify(pre_serialise(state));
                await Preferences.set({ key, value });
            },
            async restoreState(key) {
                const get_result = await Preferences.get({ key });
                return post_deserialise(JSON.parse(get_result.value));
            },
        });
        this.modules = options.modules || null;
    }

    static RESTORE_MUTATION(state, savedState) {
        Object.assign(state, savedState);
    }
}