import { ref } from "vue";
import constants from "@/constants";

export default {
    namespaced: true,
    state() {
        return {
            backend_type: null, // index into constants.BACKEND_URL
            sid: ref(null),
            uid: ref(null),
            user_name: ref(null),
            user_email: ref(null),
            user_picture: ref(null),
            user_roles: ref([]),
            group_members: ref([]),
        }
    },
    getters: {
        is_signed_in: state => state.sid !== null,
        is_manager: state => state.user_roles && state.user_roles.includes("manager"),
        has_backend_type: state => state.backend_type !== null,
    },
    mutations: {
        setBackendType(state, backend_type) {
            state.backend_type = backend_type;
        },
        setSid(state, sid) {
            state.sid = sid;
        },
        setUid(state, uid) {
            state.uid = uid;
        },
        setUserName(state, name) {
            state.user_name = name;
        },
        setUserEmail(state, email) {
            state.user_email = email;
        },
        setUserPicture(state, url) {
            state.user_picture = url;
        },
        setUserRoles(state, roles) {
            state.user_roles = roles;
        },
        setGroupMembers(state, members) {
            state.group_members = members;
        },
    },
    actions: {
        setUserDetails(context, data) {
            context.commit("setUid", data.uid);
            context.commit("setUserName", data.name || constants.USERNAME_PLACEHOLDER);
            context.commit("setUserEmail", data.email);
            context.commit("setUserPicture", data.picture || constants.AVATAR_PLACEHOLDER_URL);
            context.commit("setUserRoles", data.roles);
            context.commit("setGroupMembers", []);
        },
        setGroupMembers(context, members) {
            context.commit("setGroupMembers", members);
        },
        reset(context) {
            context.commit("setSid", null);
            context.commit("setUid", null);
            context.commit("setUserName", null);
            context.commit("setUserEmail", null);
            context.commit("setUserPicture", null);
            context.commit("setUserRoles", null);
        },

    },
};