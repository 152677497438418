import { DateTime } from "luxon";
import { ref } from "vue";
import { createStore } from "vuex";

import { VuexCapacitorPersistence } from "@/vuex-cap-persist";

import persist from "./persist";
import work from "./work";


const vuex_persist = new VuexCapacitorPersistence({
    modules: [ "persist", "work" ],
});


const store = createStore({
    state() {
        return {
            now: ref(DateTime.now()),
        }
    },
    getters: {
    },
    mutations: {
        RESTORE_MUTATION: VuexCapacitorPersistence.RESTORE_MUTATION,
        tick(state) {
            state.now = DateTime.now();
        },
    },
    plugins: [
        vuex_persist.plugin,
    ],
    modules: {
        persist,
        work,
    }
});

setInterval(() => store.commit("tick"), 1000);

export default store;
