import constants from "@/constants";
import { obj_from_keys } from "@/utils";

export default {
    namespaced: true,
    state() {
        return {
            service_in_progress: obj_from_keys(constants.SERVICE_TYPES, false),
        };
    },
    getters: {
        any_service_in_progress: state => Object.values(state.service_in_progress).some(x => x),
    },
    mutations: {
        resetServices(state) {
            for (const wt of constants.SERVICE_TYPES) {
                state.service_in_progress[wt] = false;
            }
        },
        doService(state, wt) {
            state.service_in_progress[wt] = true;
        },
        undoService(state, wt) {
            state.service_in_progress[wt] = false;
        },
    },
    actions: {
        reset(context) {
            context.commit("resetServices");
        },
    },
}
