import { alertController, toastController } from "@ionic/vue";
import { FirebaseError } from "firebase/app";
import { AxiosError } from "axios";
import { checkmark, close } from "ionicons/icons";

export function error_details(error) {
    if (error instanceof FirebaseError) {
        return error.code;
    }
    if (error instanceof AxiosError) {
        if (error.response) {
            return `${error.response.status} for ${error.config.method} ${error.config.url}`;
            // console.log(error.response.headers);
        }
        if (error.request) {
            return `no response for ${error.config.method} ${error.config.url}`;
        }
        return error.message;
    }
    return error;
}

export async function show_error(error) {
    const details = error_details(error);
    console.log(`ERROR: ${details}`);

    const alert = await alertController.create({
        header: "ERROR",
        // subHeader: "Important message",
        message: details,
        buttons: ["OK"],
    });
    await alert.present();
}


export async function show_toast(message, duration=0) {
    const buttons = [];
    if (duration === 0) {
        buttons.push({
            icon: close,
            role: "cancel",
        });
    }
    const toast = await toastController.create({
        message,
        duration,
        buttons,
        position: "middle",
    });
    await toast.present();
}


export function show_confirm({ message, color, cssClass, icon }) {
    return new Promise(async resolve => {
        const buttons = [
            {
                icon: close,
                handler: () => resolve(false),
            },
            {
                icon: checkmark,
                handler: () => resolve(true),
            },
        ];
        const toast = await toastController.create({
            message,
            color,
            cssClass,
            icon,
            duration: 0,
            buttons,
            position: "middle",
        });
        await toast.present();
    });
}
