<template>
  <ion-modal id="spinner-modal" ref="spinner_modal_ref" :can-dismiss="canDismissFn">
    <ion-spinner name="lines-sharp"/>
  </ion-modal>
</template>

<script setup>
import { ref } from "vue";
import {
  IonModal,
  IonSpinner,
} from "@ionic/vue";

const spinner_modal_ref = ref(null);

let can_dismiss = false;

const canDismissFn = () => can_dismiss;

const present = () => {
  const modal = spinner_modal_ref.value.$el;
  can_dismiss = false;
  return modal.present();
};

const dismiss = () => {
  const modal = spinner_modal_ref.value.$el;
  can_dismiss = true;
  return modal.dismiss();
};

defineExpose({
  present,
  dismiss,
});
</script>

<style scoped>
ion-modal#spinner-modal {
  --backdrop-opacity: 0.75;
}

ion-modal#spinner-modal::part(content) {
  display: flex;
  background: #0000;
  align-items: center;
  justify-content: center;
}

ion-modal#spinner-modal ion-spinner {
  color: #fff;
  width: 33vw;
  height: 33vw;
}
</style>
