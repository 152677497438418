<template>
  <ion-modal id="employee-picker-modal" ref="employee_picker_modal_ref">
    <ion-list>

      <ion-item v-for="(item, idx) in employees" :key="`employee-${idx}`" @click="employee_picker_done(item, idx)">
        <ion-avatar slot="start">
          <img :alt="item.name" :src="item.picture || AVATAR_PLACEHOLDER_URL" referrerpolicy="no-referrer"/>
        </ion-avatar>
        <ion-label>{{ item.name }}</ion-label>
      </ion-item>

      <ion-item>
        <ion-button class="list-full-row" expand="block" @click="employee_picker_cancel">
          <ion-icon :icon="arrowUndo"/>
        </ion-button>
      </ion-item>

    </ion-list>
  </ion-modal>
</template>

<script setup>
import { reactive, ref } from "vue";
import {
  IonAvatar,
  IonButton,
  IonIcon, IonItem,
  IonLabel, IonList,
  IonModal,
} from "@ionic/vue";
import {
  arrowUndo,
} from "ionicons/icons";
import constants from "@/constants";
import { call_backend } from "@/backend";


const employee_picker_modal_ref = ref(null);
const employee_picker_resolve = [];

const AVATAR_PLACEHOLDER_URL = constants.AVATAR_PLACEHOLDER_URL;
const employees = reactive([]);

const modal = () => employee_picker_modal_ref.value.$el;

const pick_employee = async () => {
  const new_employees = await call_backend("GET", "/v1/person", { role: "employee" });
  employees.splice(0, employees.length, ...new_employees);
  await modal().present();
  return new Promise(resolve => employee_picker_resolve.push(resolve));
};


const employee_picker_done = async item => {
  const resolve = employee_picker_resolve.pop();
  await modal().dismiss();
  if (resolve) {
    resolve(item.uid);
  }
};

const employee_picker_cancel = async () => {
  const resolve = employee_picker_resolve.pop();
  await modal().dismiss();
  if (resolve) {
    resolve();
  }
};

defineExpose({
  pick_employee,
});

</script>

<style scoped>

ion-modal#employee-picker-modal {
  --width: fit-content;
  --min-width: 250px;
  --height: fit-content;
  --border-radius: 6px;
  --box-shadow: 0 28px 48px rgba(0, 0, 0, 0.4);
}

ion-button.list-full-row {
  width: 100%;
}

</style>
