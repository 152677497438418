<template>
  <ion-app>
    <time-picker ref="time_picker_modal_ref"/>
    <modal-spinner ref="spinner_modal_ref"/>
    <employee-picker ref="employee_picker_modal_ref"/>

    <div class="app-screen">
      <ion-router-outlet/>

      <ion-buttons class="tabs" v-show="show_history_menu">
        <link-button href="/history/invoices" :icon="fileTray">
          Invoices
        </link-button>
        <link-button href="/history/daily-stats" :icon="calendar">
          Daily
        </link-button>
      </ion-buttons>

      <ion-buttons class="tabs">
        <link-button href="/purchases" :icon="layers" v-if="is_manager">
          Purchases
        </link-button>
        <link-button href="/working" :icon="cut" :disabled="!is_signed_in" v-else>
          Work
        </link-button>
        <link-button href="/history" :icon="statsChart" :disabled="!is_signed_in">
          History
        </link-button>
        <link-button :href="sign_inout_route" :icon="sign_inout_icon" :disabled="!has_backend_type">
          {{ sign_inout_label }}
        </link-button>
      </ion-buttons>

    </div>
  </ion-app>
</template>

<script setup>
import { computed, onMounted, provide, ref } from "vue";
import { useRouter } from "vue-router";
import { useStore } from "vuex";
import {
  IonApp,
  IonButtons,
  IonRouterOutlet,
} from "@ionic/vue";
import {
  calendar, cut,
  fileTray,
  layers, logIn, logOut,
  statsChart,
} from "ionicons/icons";

/*
NOTE:
We need a two-level tabs here (work/history/loginout + invoices/daily within history), and when moving
from a nested page (eg. history/invoices) on the top level (eg. to work), then the ionViewDidLeave event is not
fired.
This is reasonable if we consider that history conserves the selection of invoices, but we need to invalidate
certain data when un-displaying invoices, so we need that ionViewDidLeave event.

IonTabs doesn't support more than one IonTabBar (duh...), so we can't just have all the tabs logically on the
same level but visually distinguished either.

Therefore, we can't use (native) nested tabs, therefore we can have only one router-outlet, so we need our own
tab bar and tab buttons.
*/

import EmployeePicker from "@/components/EmployeePicker.vue";
import ModalSpinner from "@/components/ModalSpinner.vue";
import LinkButton from "@/components/LinkButton.vue";
import TimePicker from "@/components/TimePicker.vue";

const time_picker_modal_ref = ref(null);
const spinner_modal_ref = ref(null);
const employee_picker_modal_ref = ref(null);

const router = useRouter();
const store = useStore();

const has_backend_type = computed(() => store.getters["persist/has_backend_type"]);
const is_manager = computed(() => store.getters["persist/is_manager"]);
const is_signed_in = computed(() => store.getters["persist/is_signed_in"]);

const sign_inout_route = computed(() => is_signed_in.value ? "/sign-out" : "/sign-in");
const sign_inout_icon = computed(() => is_signed_in.value ? logOut : logIn);
const sign_inout_label = computed(() => is_signed_in.value ? "Sign Out" : "Sign In");

const show_history_menu = computed(() => router.currentRoute.value.path.startsWith("/history"));

provide("time_picker", time_picker_modal_ref);
provide("spinner", spinner_modal_ref);
provide("employee_picker", employee_picker_modal_ref);

onMounted(() => {
  console.log("App mounted");
});

</script>

<style scoped>

div.app-screen {
  height: 100%;
  display: flex;
  flex-direction: column;
}

div.app-screen ion-router-outlet {
  position: static;
  flex-grow: 1;
}

ion-buttons.tabs {
  height: 56px;
}

</style>
